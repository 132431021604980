.header{
        width: 100%;
        height: 80px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        background-image: url('../assets/nav.png');
        background-size: 100% 80px;
        background-repeat: no-repeat;
        position: relative;
        z-index: 15;
}

.fullScreenIcon{
    color: #03DDFD;
    position: absolute;
    top:6px;
    left: 15px;
    cursor: pointer;
    transition: transform 0.3s;
    font-size: 24px;
}

.fullScreenIcon:hover{
    transform: scale(1.1);
}

.digitalClock{
    position: absolute;
    top:10px;
    right:50px;
}