.main{
    background: #FFFFFF;
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.06);
   /* width: 100%;*/
    height: 60px;
    padding: 10px;
    padding-left: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logInfo{
    width: 220px;
    border-left: 1px solid #EAEDF3;
    display: flex;
}

.avatar{
    margin:0 20px;
}

.user{
    display: flex;
    align-items: center;
}

.userName{
    color: #95A0AA;
    font-size: 16px;
    margin-right: 30px;
    max-width:100px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.title{
    color: rgba(0, 0, 0, 0.65);
    font-size: 18px;
    font-weight: bold;
}