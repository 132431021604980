@import '~antd/dist/antd.css';

.ant-layout-sider-trigger{
    position: absolute;
}

.ant-checkbox-checked .ant-checkbox-inner{
    background: linear-gradient(#2d9c3c,#34aa44,#38b249);
    border-color: #2d9c3c;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: #2d9c3c;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub{
    background:#1f2a2c!important;
    box-shadow:0px 0px 0px;
}
.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 60px;
    line-height: 60px;
}

.ant-layout-sider-children,.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
    background-color:#1a2325;
}
.ant-layout-sider-trigger{
    background-color:#2c3b3e;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
    background-color:#1f2a2c!important;
}
.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover{
    color:#1890ff;
}
.ant-menu-submenu .ant-menu-submenu-vertical .ant-menu-submenu-open .ant-menu-submenu-active{
    background-color:#1f2a2c!important;
}
.ant-menu-submenu .ant-menu-submenu-popup .ant-menu-dark .ant-menu-submenu-placement-rightTop{
    background-color:#1f2a2c!important;
}
.editBtn{
    background-image: linear-gradient(0deg, #34AA44 2%, #38B249 100%) !important;
    border: 1px solid #2D9C3C !important;
    box-shadow: 0 1px 1px 0 rgba(19,31,21,0.10) !important;
    border-radius: 4px ;
    width:69px !important;
    height:28px !important;
    color:white !important;
    font-size: 14px !important;
    float:right;
    margin-right:26px;
}

.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover{
    color: #03DDFD
}
.ant-menu-submenu-selected .ant-menu-submenu-title{
    background:#165e69;


}
.ant-menu-submenu-title .anticon{
    line-height:60px !important;
    display:inline-block !important;
}
.ant-menu-submenu-inline .ant-menu-item .anticon + span,.ant-menu-submenu-inline .ant-menu-submenu-title .anticon + span{
    position:absolute;
    top:-2px;
}
.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title{
    padding: 0 22px !important;
}
