.main{
    width: 100%;
    height: 100%;
    min-width: 1000px;
    position: relative;
}

.content{
    height: calc(100% - 60px);
    width: 100%;
    padding: 20px 70px;
}

.infoCard{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
    width: 100%;
    height: 180px;
}

.infoTitle{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    color:#3E3F42;
    font-weight: bold;
    font-size: 16px;
    padding-left: 50px;
}

.infoTableHeader{
    width: 100%;
    height: 40px;
    border-top: 1px solid #EAEDF3;
    border-bottom: 1px solid #EAEDF3;
    padding: 0 20px; 
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.infoTableHeaderText{
    color: #9EA0A5;
    line-height: 38px;
    font-size: 12px;
    white-space: nowrap;
}

.infoTableBody{
    width: 100%;
    height: 70px;
    padding: 0 20px; 
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.infoTableBodyText{
    color: #9EA0A5;
    line-height: 70px;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mapCard{
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 4px;
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 190px);
}

.mapHeader{
    height: 70px;
    display: flex;
    justify-content: space-between;
}

.mapHeaderLeft{
    height: 100%;
    width: 70%;
    padding: 15px 0 15px 30px;
    display: flex;
    justify-content: space-between;
    /* min-width: 700px; */
    white-space: nowrap;
}

.mapHeaderRight{
    height: 100%;
    width:20%;
    padding: 15px 20px 15px 0 ;
    display: flex;
    justify-content: center;
}

.headerItemCon{
    width: auto;
    display: flex;
}

.headerItemTitle{
    color: rgba(23,53,48,0.5);
    font-size: 12px;
    line-height: 40px;
}

.headerItemText{
    color:#262628;
    font-size: 12px;
    font-weight: bold;
    margin:0 5px;
    line-height: 40px;
}

.greenButton{
    background-image: linear-gradient(0deg, #34AA44 2%, #38B249 100%);
    box-shadow: 0 1px 1px 0 rgba(19,31,21,0.10), inset 0 2px 0 0 rgba(255,255,255,0.06);
    border: 1px solid #2D9C3C;
    border-radius: 4px;
    color: #FFFFFF;
    white-space: nowrap;
    font-size: 12px;
    height: 32px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    margin:0 20px;
}

.greenButton:hover{
    /* transform: scale(1.05); */
    opacity: 0.9;
}

.silverButton{
    background-image: linear-gradient(-1deg, #F6F7F9 2%, #FFFFFF 100%);
    border: 1px solid #D8DCE6;
    box-shadow: 0 1px 1px 0 rgba(22,29,37,0.05), inset 0 2px 0 0 rgba(255,255,255,0.05);
    border-radius: 4px;
    color: #3E3F42;
    white-space: nowrap;
    font-size: 12px;
    height: 32px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    width: 101px;
}

.silverButton:hover{
    opacity: 0.9;
}

.mapBodyCon{
    height: calc(100% - 70px);
    width: 100%;
}

/* 弹层 */
.editMain{
    width: auto;
    height: 100%;
    display: flex;
}

.editContent{
    width: 540px;
    height: 100%;
}

.editMapCon{
    width: 600px;
    height: 700px;
}

.editHeader{
    border-bottom: 1px solid  #EAEDF3;
    height: 80px;
    width: 100%
}

.editClose{
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9EA0A5;
    width: 40px;
    height: 80px;
}

.editCloseIcon{
    cursor: pointer;
    transition: color .3s
}

.editCloseIcon:hover{
    color: rgba(0,0,0,0.75)
}

.editTitle{
    line-height: 80px;
    padding-left: 20px;
    color:  #3E3F42;
    font-size: 18px;
    font-weight: bold;
}

.editEditButton{
    background-image: linear-gradient(0deg, #34AA44 2%, #38B249 100%);
    border: 1px solid #2D9C3C;
    box-shadow: 0 1px 1px 0 rgba(19,31,21,0.10), inset 0 2px 0 0 rgba(255,255,255,0.06);
    border-radius: 4px;
    color: #FFFFFF;
    width: 70px;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    margin:0 20px;
}

.editEditButton:hover{
    opacity: 0.9;
}

.editDeleteButton{
    background-image: linear-gradient(0deg, #E91C38 2%, #FF203D 100%);
    border: 1px solid #BD041B;
    box-shadow: 0 1px 1px 0 rgba(19,31,21,0.10), inset 0 2px 0 0 rgba(255,255,255,0.06);
    border-radius: 4px;
    color: #FFFFFF;
    width: 70px;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
}

.editDeleteButton:hover{
    opacity: 0.9;
}

.editHeaderLeft{
    display: flex;
    width:90%;
    justify-content: space-between;
    align-items: center;
}

.editButtonGroup{
    display: flex;
    width:auto;
    justify-content: space-between;
    align-items: center;
}

.editBody{
    height: 540px;
    width: 100%;
    padding: 10px 20px 0 20px;
    overflow: auto;
}

.editFooter{
    height: 80px;
    width: 100%;
    border-top: 1px solid  #EAEDF3;
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
}

.editMarkerInfo{
    background: #FBFBFD;
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    width: 100%;
    height: 70px;
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;
}

.editMarkerInfoTitle{
    font-size: 12px;
    color: #9EA0A5;
    margin-right: 10px;
}

.editMarkerInfoText{
    font-size: 14px;
    color: #3E3F42;
}

.editMarkerInfCon{
    border-bottom: 1px solid #EAEDF3;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.editMarkerTypeCon{
    min-height: 200px;
    width: 100%;
    padding-bottom:15px;
    border-bottom: 1px solid #EAEDF3;
    margin-bottom:10px;
}

.editMarkerTypeHeader{
    color: #3E3F42;
    height: 20px;
    line-height: 20px;
    padding-left: 20px;
    font-weight: bold;
    margin:10px 0;
}

.editMarkerTypeBody{
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.editTypePane{
    background: #FFFFFF;
    border: 1px solid #E2E5ED;
    border-radius: 4px;
    height: 70px;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
}

.editTypePane:hover{
    background: rgba(10,189,103,0.02);
    border: 2px solid #34AA44;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
}

.editSelectTypePane{
    background: rgba(10,189,103,0.02);
    border: 2px solid #34AA44;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
    border-radius: 4px;
    height: 70px;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.editTypeImg{
    width: 48px;
    height: 48px;
}

.editMarkerText{
    color: #3E3F42;
    line-height: 50px;
    font-weight: bold;
    margin-right: 10px;
}

.editSpeedInput{
    width: 70px;
}


.editSpeedInput:hover{
    border-color:#34AA44 !important;
}

.editMarkerTypeImgWrapper{
    width: 48px;
    height: 48px;
    margin-right: 10px;
    position: relative;
}

.editSpeedLimit{
    position: absolute;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color:#000000;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    margin:auto;
}

.editLatlngCon{
    width: 100%;
}

.editLatlngHeader{
    font-size: 12px;
    color: #9EA0A5;
    margin:10px 0;
}

.editLatlngTabCon{
    height: 30px;
    display: flex;
    margin-bottom: 10px;
}

.editLatlngTab{
    height: 100%;
    width: 60px;
    margin:0 10px;
    font-size: 12px;
    color: #9EA0A5;
    text-align: center;
    cursor: pointer;
}

.editLatlngTabSelected{
    height: 100%;
    width: 60px;
    margin:0 10px;
    font-size: 12px;
    color: #9EA0A5;
    border-bottom: 2px solid #1665D8;
    text-align: center;
    cursor: pointer;
}

.editCancelButton{
    background-image: linear-gradient(-1deg, #F6F7F9 2%, #FFFFFF 100%);
    border: 1px solid #D8DCE6;
    box-shadow: 0 1px 1px 0 rgba(22,29,37,0.05), inset 0 2px 0 0 rgba(255,255,255,0.05);
    border-radius: 4px;
    width: 80px;
    height: 40px;
    color:#3E3F42;
    cursor: pointer;
}

.editSaveButton{
    background: linear-gradient(0deg, #34AA44 2%, #38B249 100%);
    border: 1px solid #2D9C3C;
    box-shadow: 0 1px 1px 0 rgba(19,31,21,0.10), inset 0 2px 0 0 rgba(255,255,255,0.06);
    border-radius: 4px;
    width: 150px;
    height: 40px;
    color:#FFFFFF;
    cursor: pointer;
}

.editLatlngTabMap{
    width: 100%;
    height: 70px;
    border: 1px dashed #EAEDF3;
    border-radius: 4px;
    padding: 10px 20px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editLatlngTabLeft{
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9EA0A5;
}

.editCircle{
    background: #FFFFFF;
    border: 1px solid rgba(0,0,0,0.06);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
    border-radius: 60px;
    width: 38px;
    height: 38px;
}

.editLatlngTabRight{
    width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editLatlngSignButton{
    width: 90px;
    height: 40px;
    background-image: linear-gradient(0deg, #34AA44 2%, #38B249 100%);
    border: 1px solid #2D9C3C;
    box-shadow: 0 1px 1px 0 rgba(19,31,21,0.10), inset 0 2px 0 0 rgba(255,255,255,0.06);
    border-radius: 4px;
    color:#FFFFFF;
    cursor: pointer;
}

.editLatlngResignButton{
    width: 90px;
    height: 40px;
    border: 1px solid #E2E5ED;
    border-radius: 4px;
    color: #3E3F42;
    background-color: transparent;
    cursor: pointer;
}

.editSaveButton:disabled{
    color: rgba(0,0,0,0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
}

button:disabled{
    color: rgba(0,0,0,0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
}

.LatLngCon{
    width: 100%;
    display: flex;
}

.latLngSelectCon{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.direPointCon{
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.latlngInputCon{
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.latlngInputHeader{
    font-size: 12px;
    color: #9EA0A5;
}