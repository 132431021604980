.main{
    width: 100%;
    height: 660px;
    display: flex;
}

.left{
    width:calc(50% - 15px);
    margin-right:15px;
    height: 100%; 
}

.right{
    width:calc(50% - 15px);
    margin-left:15px;
    height: 100%;
    position: relative;
}

.leftTop{
    width: 100%;
    height: 200px;
}

.leftBottom{
    width: 100%;
    height: 460px;
}

.header{
    width: 100%;
    display: flex;
    align-items: center;
    color: #3E3F42;
    font-size: 16px;
    font-weight: bold;
    height: 80px;
}

.content{
    background: #FBFBFD;
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    overflow-y: auto;
    padding: 10px;
}

.leftTopContent{
    height: 120px;
    display: grid;
    grid-template-columns: 35% 60%;
    grid-gap: 10px;
}

.leftBottomContent{
    height: 380px;
    display: grid;
    grid-template-columns: 35% 60%;
    grid-gap: 10px;
}

.rightTopContent{
    height: 120px;
    display: grid;
    grid-template-columns: 35% 60%;
    grid-gap: 10px;
}

.rightBottomContent{
    height: 380px;
    padding: 10px 20px;
}

.itemTitle{
    text-align: right;
    color: #9EA0A5;
}

.itemText{
    font-weight: bold;
}

.projectCon{
    width: 100%;
    margin-bottom: 10px;
}

.projectTitle{
    font-size: 16px;
    color: #3E3F42;
    height: 30px;
    font-weight: bold
}

.sceneCon{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.projectText{
    color: #9EA0A5;
    font-size: 14px;
}

.projectTimeRange{
    color: #3E3F42;
    font-size: 12px;
    font-weight: bold;
}

.planStatuDone{
    background-image: linear-gradient(0deg, #34AA44 2%, #38B249 100%);
    border: 1px solid #2D9C3C;
    box-shadow: 0 1px 1px 0 rgba(19,31,21,0.10), inset 0 2px 0 0 rgba(255,255,255,0.06);
    border-radius: 2px;
    color: #FFFFFF;
    font-size: 12px;
    margin-left: 10px;
    width: 50px;
    text-align: center;
}

.planStatuTesting{
    background-color: #FACF55;
    border:1px solid #FACF55;
    border-radius:2px;
    color: #FFFFFF;
    font-size: 12px;
    margin-left: 10px;
    width: 50px;
    text-align: center;
}

.planStatuRed{
    background-color: #F62440;
    border:1px solid  #F62440;
    border-radius: 2px;
    color: #FFFFFF;
    font-size: 12px;
    margin-left: 10px;
    width: 50px;
    text-align: center;
}

.inputHeader{
    font-size: 14px;
    color: #9EA0A5;
    display: block;
    height: 30px;
}

.warning{
    position: absolute;
    bottom:0;
    color: #F62440;
    font-size: 12px;
    left: 0;
}

.submitButton{
    position: absolute;
    bottom:-70px;
    right:10px;
    width: 150px;
    height: 40px;
}

.submitButton[disabled]{
    color: rgba(0,0,0,0.25);
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
    background-image:none;
}

.draftButton{
    position: absolute;
    bottom:-70px;
    right:200px;
    width: 150px;
    height: 40px;
}

.fieldCon{
    position: relative;
    height: 85px;
}

.select{
    width: 240px;
}

.editBottom{
    width: 100%;
    overflow: hidden;
    height: 325px;
}

.schemeSubTitle{
    font-size: 12px;
    color: #9EA0A5;
    margin-left: 15px;
    margin-top:6px;
    font-weight: normal;
}

.projectConfigCon{
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    overflow-y: auto;
    height: calc(100% - 80px);
}